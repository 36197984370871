<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col">
      <section class="card">
        <section class="card-body">

          <!-- TITLE -->
          <div class="row justify-content-center mb-3">
            <div class="col">
              <h4 style="text-align: center;" class="section-title">Facility Lookup</h4>
            </div>
          </div>

          
          <div class="row">
            <!-- facility search -->
            <div class="col-5">
              <!--<app-facility-list #facilityResults></app-facility-list>-->
            </div>
            <!-- MAP -->
            <div class="col-7">
              <!--<app-map-view [facilities]="facilityResults.facilitySearchResults"></app-map-view>-->
            </div>
          </div>

        </section>          
        </section>
</div>
  </div>
</div>
