import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ManageUsersService, AlertsService, Facilities } from '../../shared';
import { Router } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { FacilityService } from '../../shared/services/facility.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  userName = new FormControl('', [Validators.required]);
  processing = false;
  emailValidator = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  //emailValidator = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  emailAddress = new FormControl('', [Validators.maxLength(60), Validators.pattern(this.emailValidator)]);
  //facilitySearch = new FormControl('', [Validators.required]);
  searchFac: Facilities;
  facilityId: number;
  filteredFacilitiesSearch: Observable<Facilities[]>;
  facilitySearching = false;
  facilitySearch1 = new FormControl('', [Validators.required]);

  constructor(private manageUserService: ManageUsersService,
    private alertsService: AlertsService,
    private _facilityService: FacilityService,
    public router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.searchFacility();
  }

  facilitySelected22(event: MatAutocompleteSelectedEvent) {
    this.searchFac = event.option.value as Facilities;
    this.facilityId = this.searchFac.facilityKey;
    this.facilitySearch1.setValue(this.searchFac.name);

    var abc = '';
  }

  searchFacility() {

    this.facilitySearch1.valueChanges.pipe(
      filter((value: string) => {
        if (value == null || value == '') {
          this.filteredFacilitiesSearch = null;
          return false;
        }
        return value.length >= 2;
      }),
      debounceTime(1000)
    ).subscribe((value: string) => {
      this.facilitySearching = true;
      this.filteredFacilitiesSearch = this._facilityService.searchFacility(value, value, value, null);
      this.filteredFacilitiesSearch.subscribe(val => {
        this.facilitySearching = false;
      });
    });
  }

  onForgotPassword() {
    this.processing = true;
    this.userName.disable();
    let emailAddress = this.emailAddress.value.toString().toUpperCase();
    let facilityId = this.facilityId;
    let username = this.userName.value.toString().toUpperCase();
    this.manageUserService.resetPassword(facilityId, emailAddress).subscribe(resp => {
      this.emailAddress.enable();
      //console.log(resp);
      this.processing = false;
      if (resp.message == 'Success') {
        this.alertsService.showSucessMessage("Password has been reset successfully ! <br/> An email has been sent with your new password.", 'Success');
      }
      else {
        this.alertsService.showErrorMessage(`A user with emailAddress '${emailAddress}' not found !`, 'invalid username');
      }
    }, err => {
      this.userName.enable();
      //console.error(err);
      this.processing = false;
      if (err.error) {
        if (err.error.errorCode == 1) {
          this.alertsService.showErrorMessage(`A user with emailAddress '${emailAddress}' not found !`, 'invaid username');
        }
      }
    });

  }

}
