<!--TFS 2456All Section - Move ALOCQ ID and Consumer Name outside-->
<nav class="navbar navbar-top-fixed navbar-expand-md navbar-light bg-white box-shadow mb-3">
  <div class="container">
    <mat-card-title>
      Document ID: {{dataService.getSelectedDocument()}}
    </mat-card-title>
    <mat-card-title>
      Consumer Name: {{dataService.getSelectedDocumentName()}}
    </mat-card-title>
  </div>
</nav>
