import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MessageBoxComponent } from '../components/message-box/message-box.component';

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

  constructor(public dialog: MatDialog) { }
  
  showMessage(options: {
    title?: string,
    message?: string,
    confirm?: boolean,
    warning?: boolean,
    error?: boolean,
    sucess?: boolean,
    alignTitle?:string,
    alignMessage?:string
  }): Observable<any> {
    return this.dialog.open(MessageBoxComponent, {
      disableClose:true,
      data: options
    }).afterClosed();
  }

  success(options: {
    title?:string ,
    message?: string 
  }): Observable<any> {

    return this.dialog.open(MessageBoxComponent, {
      disableClose: true,
      data: {
        title: options.title,
        message: options.message,
        alignMessage: 'center',
        alignTitle: 'center',
        sucess: true
      }
    }).afterClosed();

  }

  error(options: {
    title?: string,
    message?: string
  }): Observable<any> {

    return this.dialog.open(MessageBoxComponent, {
      disableClose: true,
      data: {
        title: options.title,
        message: options.message,
        alignMessage: 'center',
        alignTitle: 'center',
        error: true
      }
    }).afterClosed();

  }

  warn(options: {
    title?: string,
    message?: string
  }): Observable<any> {

    return this.dialog.open(MessageBoxComponent, {
      disableClose: true,
      data: {
        title: options.title,
        message: options.message,
        alignMessage: 'center',
        alignTitle: 'center',
        warn: true
      }
    }).afterClosed();

  }

  confirm(options: {
    title?: string,
    message?: string,
    warn?: boolean
  }): Observable<any> {

    return this.dialog.open(MessageBoxComponent, {
      disableClose: true,
      data: {
        title: options.title,
        message: options.message,
        alignMessage: 'center',
        alignTitle: 'center',
        confirm: true,
        warn: options.warn
      }
    }).afterClosed();

  }

}
