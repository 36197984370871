import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loaderSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public loaderState = this.loaderSubject.asObservable().pipe(distinctUntilChanged());
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }


  getLoader(): Observable<any> {
    return this.loaderSubject.asObservable();
  }

  showLoader() {
    this.loaderSubject.next(true);
  }
  hideLoader() {
    this.loaderSubject.next(false);
  }

  display(value: boolean) {
    this.status.next(value);
  }
}
