import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { ManageUsersModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {

  constructor(private apiService: ApiService) { }

  getManageusers(username): Observable<ManageUsersModel[]> {
    return this.apiService.get('ManageUser/GetUserList/GetUserlistAsync/' + username)
      .pipe(map(data => { return data; }));
  }

  getsearchManageusers(firstname, lastname, username, institutionId): Observable<ManageUsersModel[]> {
    var url = "ManageUser/GetUserlistbyusername/GetUserlistbyusername/" + username + "/";
    var fname = firstname == '' ? null : firstname;
    var lname = lastname == '' ? null : lastname;
    if (institutionId != null)
      url = url + fname + "/" + lname + "/" + institutionId;
    else
      url = url + fname + "/" + lname;
    return this.apiService.get(url)
      .pipe(map(data => { return data; }));

  }


  deleteManageUser(userName): Observable<any> {
    return this.apiService.delete('ManageUser/DeleteUser/DeleteUserAsync/' + userName)
      .pipe(map(data => { return data; }));
  }
  saveuser(user): Observable<any> {
    return this.apiService.post('ManageUser/AddNewUser/AddNewUserAsync', user)
      .pipe(map(data => {
        return data;
      }));
  }
  updateuser(user): Observable<any> {
    return this.apiService.post('ManageUser/UpdateUser/UpdateUserAsync', user)
      .pipe(map(data => {
        return data;
      }));
  }
  getinstitutions(institutiontype, odaAgency): Observable<any> {
    var url = "ManageUser/GetInstitutionAPI/GetInstitutionAPI/" + institutiontype;
    if (odaAgency != null && odaAgency != undefined)
      url = url + "/" + odaAgency
    return this.apiService.get(url)
      .pipe(map(data => { return data; }));
  }
  getAgencies(): Observable<any> {
    return this.apiService.get('ManageUser/GetAgenciesValues/GetAgenciesValues')
      .pipe(map(data => { return data; }));
  }
  getusertypes(username): Observable<any> {
    return this.apiService.get('ManageUser/GetUserTypescbo/GetUserTypescboAsync/' + username)
      .pipe(map(data => { return data; }));
  }
  getloadBoards(): Observable<any> {
    return this.apiService.get('ManageUser/GetLocalBoards/GetLocalBoards')
      .pipe(map(data => { return data; }));
  }
  resendEmail(username): Observable<any> {
    return this.apiService.get('ManageUser/ResendEmailtouser/ResendEmailAsync/' + username)
      .pipe(map(data => { return data; }));
  }
  getPIMSUserslist(usertype): Observable<any> {
    return this.apiService.get('ManageUser/GetPIMSUserslist/GetPIMSUserslistAsync/' + usertype)
      .pipe(map(data => { return data; }));
  }

  resetPassword(facilityId: number, emailAddress: string): Observable<any> {
    return this.apiService.postNoloader(`User/ResetPassword?facilityId=${facilityId}&emailAddress=${emailAddress}`);
  }

}
