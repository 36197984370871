import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-antigen-supply-dialog',
  templateUrl: './antigen-supply-dialog.component.html',
  styleUrls: ['./antigen-supply-dialog.component.css']
})
export class AntigenSupplyDialogComponent implements OnInit {
  public selectSteps: number = 1;
  public maxBoxSupplyNum: number = 0;
  public antigenBoxEntered: number = 1;
  public antigenBoxEnteredIsValid: boolean = true;
  get currentSteps(): number {
    return this.selectSteps;
  }
  constructor(
    public dialogRef: MatDialogRef<AntigenSupplyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.maxBoxSupplyNum = data.boxNum;
    this.antigenBoxEntered = data.boxNum;
  }

  ngOnInit(): void {

  }

  next(): void {
    if (this.selectSteps == 1) {
      this.selectSteps = 2;
    }
    else if (this.selectSteps == 2) {
      this.selectSteps = 3;
    }
  }

  goBack(): void {
    if (this.selectSteps == 1) {
      this.selectSteps = 3;
    }
    else if (this.selectSteps == 3) {
      this.selectSteps = 1;
      this.antigenBoxEntered = this.maxBoxSupplyNum;
    }
  }

  submitAntigenBox(): void {
    this.dialogRef.close(this.antigenBoxEntered);
  }

  supplyBoxOnChange(value : string) : void {
    if(!this.validateNumberInRange(value)){
      this.antigenBoxEnteredIsValid = false;
    }else{
      this.antigenBoxEnteredIsValid = true;
    }
  }
  validateNumberInRange(inputVal: string): boolean {
    if (isNaN(+inputVal) || inputVal == undefined) {
       return false
    } else {
       const targetNum = parseInt(inputVal, 10); //remove leading zeros
       if(targetNum < 1 || targetNum > this.maxBoxSupplyNum){
           return false;
       }
       this.antigenBoxEntered = targetNum;
       return true;
   }
  } 

}
