import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { tap } from 'rxjs/operators';
import { ApiService, DataService } from '.';

@Injectable({
  providedIn: 'root'
})
export class PharmacyService {

  constructor(
    private _httpService: ApiService,
    private _dataservice: DataService) { }

  contextPharmacyLoaded = new Subject<any>();

  getPharmacy(id: number | string): Observable<any> {
    return this._httpService.get(`Pharmacy/GetPharmacy?id=${id}`, null, false);
  }

  getContextPharmacy(): Observable<any> {
    const id = this._dataservice.pharmacy.getContext();
    return this.getPharmacy(id).pipe(tap(ph => this.contextPharmacyLoaded.next(ph)));
  }

  getContacts(id:number): Observable<any> {
    return this._httpService.get(`Pharmacy/GetContacts?Id=${id}`, null, false);
  }

  getCounties(id: any): Observable<any> {
    return this._httpService.get(`Pharmacy/GetCounties?PharmacyId=${id}`, null, false);
  }

  getProjection(params: any): Observable<any> {
    return this._httpService.post(`Pharmacy/GetPharmacyProjection`, params, false);
  }

  search(keyword: string): Observable<any> {
    return this._httpService.get(`Pharmacy/Search/${keyword}`, null, false);
  }

  deleteContact(id: string): Observable<any> {
    return this._httpService.post(`Pharmacy/DeleteContact?id=${id}`, null, false);
  }

  addContact(contact: any): Observable<any> {
    return this._httpService.post(`Pharmacy/AddContact`, contact, false);
  }

  updateContact(contact: any): Observable<any> {
    return this._httpService.post(`Pharmacy/UpdateContact`, contact, false);
  }

  getAllPharmacies(): Observable<any> {
    return this._httpService.get('Pharmacy/GetAll', null, false);
  }
 

}
