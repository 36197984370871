import { DomainValue, County } from './DomainValueTypes'
import { formatDate } from '@angular/common'

export type TestSchedule = {

  id: number;
  facilityKey: string;
  status: string;
  testingProcess: string;
  ngRegion: string;
  labName: string;
  labLink: string;
  labContact: string;
  labPhone: string;
  fromDate: Date;
  toDate: Date;
  residentCount: number;
  staffCount: number;
  patientCount: number;
  type: string;
  comment: string;
  createWorker: string;
  createDate: Date;
  county: string;
  category: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  zip: number;
  state: string;
  dbaName: string;
  key: string;
  facilityId: number;
  stateId: string;
  disableEdit: boolean;

}

export type FacilitySearch =
  {
    stateId: string;
    facilityKey: string;
    name: string;
    address1: string;
    city: string;
    state: string;
    zip: string;
    county: string;
    latitude?: number;
    longitude?: number;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
  }

export type ScheduleSearchModel = {
  facilityName: string;
  facilityKey: string;
  facilityCounty: string;
  fromDate: Date;
  toDate: Date;
  county: string;
  countyCode: string;
  processType: string;
  ngRegion: string;
  labName: string;
  testType: string;
  licenseNo: string;
  lastUpdateDate: Date;
}

export type AddScheduleModel = {
  id:number,
  facilityName: string;
  facilityKey: string;
  facilityCounty: string;
  fromDate: Date;
  toDate: Date;
  county: string;
  countyCode: string;
  processType: string;
  ngRegion: string;
  labName: string;
  labContact: string;
  labPhone: string;
  testType: string;
  residentCount: number;
  staffCount: number;
  patientCount: number;
  comment: string;
}

export type ShedulePopUpModel = {
  selectedSchedule: TestSchedule,
  processType: DomainValue[],
  nationGurdRegions: DomainValue[],
  testingTypes: DomainValue[],
  counties: County[],
  odaHolidays: ODAHoliday[]
}

export class ODAHoliday  {

  constructor(holdate, holname) {
    this.holidayDate = holdate;
    this.holidayName = holname;
  }

  _holidayData: any;
  set holidayDate(value: any) {
    this._holidayData = formatDate(value, 'MM/dd/yyy', 'en-us');
  }
  get holidayDate() {
    return this._holidayData;
  }
  holidayName: string
}

export type GeoJSONType = {
  type: string,
  properties: {
    name: string,
    amenity: string,
    popupContent: string,
    geo_point_2d: [],
    intptlat: number,
    intptlon: number,
    toolTip: any
  },
  geometry: {
    type: string,
    coordinates: []
  }
}


export type adUserInfo = {
  "code": number,
  "message": string,
  "username": string,
  "userADGroups": string[]
}
