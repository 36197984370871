import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { JwtService } from './jwt.service';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private loaderService: LoaderService
  ) { }

  private formatErrors(error: any) {
    return throwError(error);
  }

  get(path: string, params: HttpParams = new HttpParams(), showLoader: boolean = true): Observable<any> {
    if (showLoader) {
      this.loaderService.showLoader();
    }
    return this.http.get(`${environment.api_url}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  getFile(path: string, params: HttpParams = new HttpParams(), showloader = false): Observable<any> {
    if (showloader) {
      this.loaderService.showLoader();
    }    
    return this.http.get(`${environment.api_url}${path}`, { responseType: 'blob' })
      .pipe(catchError(this.formatErrors));
  }

  postGetFile(path: string, body: object = {}): Observable<any> {
    //this.loaderService.showLoader();
    return this.http.post(
      `${environment.api_url}${path}`, body, { responseType: 'blob' }
    ).pipe(catchError(this.formatErrors));
  }

  getNoLoader(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`, { params })
      .pipe(catchError(this.formatErrors), map(data => { ; return data; }));
  }


  put(path: string, body: object = {}, showLoader: boolean = true): Observable<any> {
    if (showLoader) {
      this.loaderService.showLoader();
    }
    return this.http.put(
      `${environment.api_url}${path}`, body
    ).pipe(catchError(this.formatErrors));
  }

  putNoLoader(path: string, body: object = {}): Observable<any> {
    //this.loaderService.showLoader();
    return this.http.put(
      `${environment.api_url}${path}`, body
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object = {}, showloader: boolean = true): Observable<any> {
    if (showloader) {
      this.loaderService.showLoader();
    }    
    return this.http.post(
      `${environment.api_url}${path}`, body
    ).pipe(catchError(this.formatErrors));
  }

  postNoloader(path: string, body: object = {}): Observable<any> {
    //this.loaderService.showLoader();
    return this.http.post(
      `${environment.api_url}${path}`, body
    ).pipe(catchError(this.formatErrors));
  }

  postAuth(path: string, body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.set('Accept', 'application/x-www-form-urlencoded');
    let bodydata = `username=${body.UserName}&password=${body.Password}`;

    return this.http.post(
      `${environment.api}${path}`, bodydata, { headers: headers }
    ).pipe(catchError(this.formatErrors));

  }

  postFile(path: string, formData: FormData): Observable<any> {
    return this.http.post(
      `${environment.api_url}${path}`, formData, {
      reportProgress: true,
      observe: 'events'
    }
    ).pipe(catchError(this.formatErrors));;
  }

  delete(path: string, params: HttpParams = new HttpParams(), showloader: boolean = true): Observable<any> {
    if (showloader) {
      this.loaderService.showLoader();
    } 
    return this.http.delete(
      `${environment.api_url}${path}`, { params }
    ).pipe(catchError(this.formatErrors));
  }
}
