<div *ngIf="canViewExcel==null || canViewExcel=='' || canViewExcel=='N'"   style="display:flex; justify-content:center;margin-top:20px;">
  <a style="color: #f9f3f5; background-color: #117090; font-size: 18px; font-weight: bold;" mat-raised-button href="mailto:covidtesting@age.ohio.gov">
    <mat-icon style="color: #f2f2f2;">contact_support</mat-icon>
    Any questions? Click here
  </a>
</div>
<div *ngIf="canViewExcel=='Y'"    style="display:flex; justify-content:center;margin-top:20px;">
    <a style="color: #f9f3f5; background-color: #117090; font-size: 18px; font-weight: bold;" mat-raised-button href="mailto:AgeExcel@age.ohio.gov">
    <mat-icon style="color: #f2f2f2;">contact_support</mat-icon>
    Any questions? Click here
  </a>
</div>
<nav class="navbar  navbar-expand-sm navbar-dark bg-dark mt-4 app-footer">
  <div class="container">
    <a class="navbar-brand order-md-last" href="#"><img src="assets/images/OH_OhioGovLogo_White.png"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#">
            &copy; Copyright {{year}} by Ohio Department of Aging. All Rights Reserved.
          </a>      
        </li>
      </ul>
    </div>
  </div>
  </nav>
