import { Directive , ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appOnlyDecimal]'
})
export class OnlyDecimalDirective {
  AllowedKeys = ['Tab', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowDown', 'ArrowRight', 'ArrowUp', 'Insert','.'];
  constructor(private el: ElementRef) { }


  @HostListener('keypress', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    //console.log(e);
    let regexp = /^(\d*\.)?\d+$/;
    let val = this.el.nativeElement.value;
    if (val.indexOf('.') > -1) {
      if (!regexp.test(`${val}${e.key}`)) {
        e.preventDefault();
      }
    } else {
      if (val.length == 0 && !regexp.test(e.key) ) {
        e.preventDefault();
        return;
      }
      if (val.length > 0 && e.key != '.' && !regexp.test(`${val}${e.key}`) ) {
        e.preventDefault();
        return;
      }
    }
   
    if (this.AllowedKeys.indexOf(e.key) > -1) {
      return;
    }
    
  }

}
