import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchResultModel, SearchModel } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private apiService: ApiService) { }

  getSearchLoad(pdfExport): Observable<SearchResultModel[]> {
    return this.apiService.get(`Search/exporttoexcel/${pdfExport}`).pipe(map(data => {      
      return data;
    }));
  }

  search(searchParams): Observable<SearchResultModel[]> {
    return this.apiService.post(`Search/SearchResults`, searchParams);
  }
  deletePasrr3622(id): Observable<SearchResultModel[]> {
    return this.apiService.delete(`HENSDocument/DeletePasrr3622Async/`+ id);
  }
  deleteExemption7000(id): Observable<SearchResultModel[]> {
    return this.apiService.delete(`HENSDocument/DeleteExemption7000Async/`+ id);
  }
   
}
