<h1 mat-dialog-title class="oda-title">Are you still there?</h1>
<div mat-dialog-content>

  <div>
    No activity has been detected recently.
    <br />
    Please click ‘Continue’ to remain logged in.
  </div>

  <div>
    <div style="    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    color: maroon;
">
      <span>You will be logged out in</span> <span class="ml-1" style="    position: relative;
    right: -18px;
    font-size: 11px;">{{remainingCount}}</span>
      <mat-progress-spinner class="logoff" diameter="25" mode="determinate" [value]="percentRemaining"></mat-progress-spinner>
    </div>
  </div>



</div>
<div mat-dialog-actions >
  <div style="display:flex;justify-content:center;width:100%">
    <button class="mr-3" color="primary" mat-raised-button (click)="continue()">Continue</button>
    <button mat-raised-button color="warn" (click)="logoff()" cdkFocusInitial>
      Log off
    </button>
  </div>
  
</div>
