import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../../models';
import { UserService, DataService, AlertsService } from '../../services';
import { Router } from '@angular/router';
import { Facility } from '../../models/facility.model';
import { FacilityService } from '../../services/facility.service';
import { PharmacyService } from '../../services/pharmacy.service';
import { Pharmacy } from '../../models/pharmacy.models';
import { Dashboard } from '../../models/facility-profile/facility-dashboard';
import {Location,LocationStrategy,PathLocationStrategy } from '@angular/common';
import { UpdateHeaderService } from '../../services/update-header.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService,
    private dataService: DataService,
    private alertService: AlertsService,
    private _facilityService: FacilityService,
    private _updateHeader :UpdateHeaderService,
    private _pharmacyService: PharmacyService) { }

  
  isshowNFmenu = false;
  ishowmnguser = false;
  isshownewdoc = false;
  agencyname = "";
  facilityKey: number;
  canViewExcel:string = "";

  contextPharmacy: Pharmacy;

  contextFacility: Facility;

  get viewType() {
    return this.dataService.vaccineContext.getContext().viewType;
  }
  get isAdmin() {
    return ['admin', 'r3ap', 'doddAdmin'].indexOf(this.viewType) >= 0;
  }
  profileUser: User;

  ngOnInit() {

    this._updateHeader.notifyObservable$.subscribe(res => {
      if(res.refresh){
    this.facilityKey = this.dataService.vaccineContext.getContext().contextFacility?.facilityKey;

    this._facilityService.selectDashboard(this.facilityKey).subscribe((dashboardData: Dashboard) => {
    
    this.canViewExcel = dashboardData.canViewExcel;
      
    });
      } 
      
      if(!res.refresh){
        this.canViewExcel = '';
      }
})
    
    const setContextHeader = (context) => {

      if (context == null) {
        return;
      }
      if (context.contextFacility) {
        this.contextFacility = context.contextFacility;
      }
      if (context.contextPharmacy) {
        this.contextPharmacy = context.contextPharmacy;
      }
      /*
      if (['nf', 'rcf' , 'aaa','icf' ].indexOf(context.viewType) >= 0) {
        this.contextFacility = context.contextFacility;
        this.contextPharmacy = null;
      }
      if (context.viewType == 'pharmacy') {
        this.contextPharmacy = context.contextPharmacy;
        this.contextFacility = null;
      }
      */
    }

    this.dataService.vaccineContext.contextLoaded.subscribe(context => setContextHeader(context));

    var context = this.dataService.vaccineContext.getContext();
    setContextHeader(context);

    this.dataService.vaccineContext.contextCleared.subscribe(_ => {
      this.contextFacility = null;
      this.contextPharmacy = null;
    })

  }
 
  openprofile() {
    this.router.navigate(['profile']);
  }

  openHelp() {
    this.router.navigate(['user-guide']);
  }

  openHome() {
    this.router.navigate(['home']);
  }

  logout() {
    localStorage.clear();
    this.alertService.clearLoader();
    this.dataService.clearSelectedDocument();
    //this.userService.purgeAuth();
  }
}

