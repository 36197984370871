export interface TopSummary {
  //documentid: string;
  //type: string;
  //status: string;
  //name: string;
  //ssn: string;
  //dateofbirth: string;
  //submitterfacility: string;
  //submittername: string;
  //submitdate: string;
  //paa: string;
  //clientnumber: string;
  //aS_ADLS_INDEPENDENT: string;
  //aS_ADLS_SUPERVISED: string;
  //aS_ADLS_ASSISTED: string;
  //aS_IADLS_INDEPENDENT: string;
  //aS_IADLS_SUPERVISED: string;
  //aS_IADLS_ASSISTED: string;
  //aS_MED_ADMIN: string;
  //aS_MED_ADMIN_INDEPENDENT: string;
  //aS_MED_ADMIN_SUPERVISED: string;
  //aS_MED_ADMIN_ASSISTED: string;
  //aS_BCAT_SCORE: string;
  //aS_MEDICAL_CONDITION: string;
  //aS_SKILLED_NURSING_DAYS: string;
  //aS_SKILLED_NURSING_SERVICE: string;
  //aS_SKILLED_REHAB_DAYS: string;
  //aS_SKILLED_REHAB_SERVICE: string;
}

export interface SummaryRequest {
  aS_SESSION_USER: string;
  aS_SESSION_ID: string;
  aS_BEGIN_DATE: string;
  aS_END_DATE: string;
  aS_ID: string;
  aS_TYPE: string;
  aS_SSN: string; 
  aS_MEDICAID_BILLING_NUMBER: string;
  aS_FIRST_NAME: string;
  aS_LAST_NAME: string;
  aS_CREATE_FACILITY_ID: string;
  aS_CREATE_WORKER: string; 
  aS_RECEIVE_FACILITY_ID: number;
  aS_CLIENT_NUMBER: string;
  aS_PIMS_EVENT_ID: number;
  aS_CATEGORICAL_IND: string;
  aS_LEVEL_II: string;
  aS_DATE_OF_BIRTH: string;
  aS_NF_STATUS: string;
  aS_DODD_STATUS: string;
  aS_DOCUMENT_STATUS: string;
}

export interface SummaryResponse {
  aO_ADVERSE_LOC_SCHED_DATE: string;
  aO_ASSESS_TYPE: string;
  aO_CREATE_WORKER: string;
  aO_DELAYED_EXEMPTION_REASON: string;
  aO_DELAYED_LOC_NEEDED: string;
  aO_DENIAL_REASON: string;
  aO_DOC_REQUEST_COMMENTS: string;
  aO_DOC_REQUEST_DATE: string;
  aO_ENROLLMENT_DATE: string;
  aO_ENROLLMENT_REQUEST_DATE: string;
  aO_ESTIMATED_COST: string;
  aO_LOC_DETERMINATION: string;
  aO_LOC_DETERMINATION_DATE: string;
  aO_LOC_DETERMINATION_STATUS: string;
  aO_LOC_EFFECTIVE_DATE: string;
  aO_LOC_RECOMMENDATION: string;
  aO_NEEDS_FACE_TO_FACE: string;
  aO_PAA_DETERM_NOTE: string;
  aO_PAA_REVIEW_WORKER: string;
  aO_PAS_EFFECTIVE_DATE: string;
  aO_PAS_ID: string;
  aO_PAS_VALIDATION: string;
  aO_REQUEST_COMPLETE_DATE: string;
  aS_ADLS_ASSISTED: string;
  aS_ADLS_INDEPENDENT: string;
  aS_ADLS_SUPERVISED: string;
  aS_BCAT_SCORE: string;
  aS_IADLS_ASSISTED: string;
  aS_IADLS_INDEPENDENT: string;
  aS_IADLS_SUPERVISED: string;
  aS_MEDICAL_CONDITION: string;
  aS_MED_ADMIN: string;
  aS_MED_ADMIN_ASSISTED: string;
  aS_MED_ADMIN_INDEPENDENT: string;
  aS_MED_ADMIN_SUPERVISED: string;
  aS_SKILLED_NURSING_DAYS: string;
  aS_SKILLED_NURSING_SERVICE: string;
  aS_SKILLED_REHAB_DAYS: string;
  aS_SKILLED_REHAB_SERVICE: string;
  aloC_ID: string;
  attachmenT_ID: string;
  attachmenT_NAME: string;
  categorical: string;
  cbdD_EMAIL: string;
  cbdD_FAX: string;
  cbdD_NAME: string;
  cbdD_PHONE: string;
  cbdD_REVIEW_DATE: string;
  cbdD_REVIEW_WORKER: string;
  clienT_NUMBER: string;
  consumeR_NAME: string;
  countY_CODE: string;
  creatE_DATE: string;
  creatE_FACILITY_ID: string;
  creatE_WORKER: string;
  createD_AT: string;
  dB_CREATE_FACILITY_ID: string;
  dB_ID: string;
  dB_TYPE: string;
  dS_CREATOR_ACCESS: string;
  dS_DODD_ACCESS: string;
  dS_HOSPITAL_ACCESS: string;
  dS_LOCAL_ACCESS: string;
  dS_NF_ACCESS: string;
  dS_ODMH_ACCESS: string;
  dS_PAA_ACCESS: string;
  datE_OF_BIRTH: string;
  deletE_IND: string;
  documenT_ID: string;
  documenT_TYPE: string;
  dodD_EMAIL: string;
  dodD_FAX: string;
  dodD_PHONE: string;
  dodD_REFERRAL_REASON: string;
  dodD_REVIEW_DATE: string;
  dodD_REVIEW_RESULTS: string;
  dodD_REVIEW_WORKER: string;
  dodD_STATUS: string;
  ediT_IND: string;
  firsT_NAME: string;
  fulL_ACCESS: string;
  fullvieW_IND: string;
  hospitaL_ID: string;
  hospitaL_NAME: string;
  institutioN_ID: string;
  lasT_NAME: string;
  loaD_IND: string;
  locaL_STATUS: string;
  medicaiD_NUMBER: string;
  nF_FAX: string;
  nF_ID: string;
  nF_NAME: string;
  nF_PHONE: string;
  name: string;
  odmH_EMAIL: string;
  odmH_FAX: string;
  odmH_PHONE: string;
  odmH_REASON_TEXT: string;
  odmH_REFERRAL_REASON: string;
  odmH_REVIEW_DATE: string;
  odmH_REVIEW_RESULTS: string;
  odmH_REVIEW_WORKER: string;
  odmH_STATUS: string;
  paA_EMAIL: string;
  paA_FAX: string;
  paA_NAME: string;
  paA_PHONE: string;
  paa: string;
  pimS_CLIENT_NUMBER: string;
  pimS_EVENT_ID: string;
  prinT_IND: string;
  psycH_DISCHARGE: string;
  reviewer: string;
  scorE_ID: string;
  sex: string;
  ssn: string;
  statuS_DESC: string;
  status: string;
  submiT_DATE: string;
  submitteR_EMAIL: string;
  submitteR_FACILITY: string;
  submitteR_FAX: string;
  submitteR_NAME: string;
  submitteR_PHONE: string;
  type: string;
  useR_AGENCY: string;
  useR_NAME: string;
  useR_TYPE: string;
  withdraW_IND: string;

}

      
      


//export interface SummaryResponse {

//  documentid: string;
//  type: string;
//  status: string;
//  name: string;
//  ssn: string;
//  dateofbirth: string;
//  submitterfacility: string;
//  submittername: string;
//  submitdate: string;
//  paa: string;
//  clientnumber: string;
//  aS_ADLS_INDEPENDENT: string;
//  aS_ADLS_SUPERVISED: string;
//  aS_ADLS_ASSISTED: string;
//  aS_IADLS_INDEPENDENT: string;
//  aS_IADLS_SUPERVISED: string;
//  aS_IADLS_ASSISTED: string;
//  aS_MED_ADMIN: string;
//  aS_MED_ADMIN_INDEPENDENT: string;
//  aS_MED_ADMIN_SUPERVISED: string;
//  aS_MED_ADMIN_ASSISTED: string;
//  aS_BCAT_SCORE: string;
//  aS_MEDICAL_CONDITION: string;
//  aS_SKILLED_NURSING_DAYS: string;
//  aS_SKILLED_NURSING_SERVICE: string;
//  aS_SKILLED_REHAB_DAYS: string;
//  aS_SKILLED_REHAB_SERVICE: string;

//  categorical:string;
//   lastname:string;
//  firstname:string;  
//  medicaidnumber:string;
//  documenttype:string;  
//  createdate:string; 
//  odmhstatus:string;
//  doddstatus:string;
//  localstatus:string;
//  odmhreferralreason:string;
//  odmhreviewresults:string;
//  odmhreviewdate:string;
//  doddreferralreason:string;
//  doddreviewresults:string;
//  doddreviewdate:string;
//  cbddreviewdate:string;
//  pimsclientnumber:string;
//  pimseventid:string;
  
  
//  nfname:string;
//  hospitalname:string;
//  countycode:string;
//  nfid:string;
//  hospitalid:string;
//  psycH_DISCHARGE:string;
//  dB_ID:string;
//  statusdesc:string;
//  odmhreviewworker:string;
//  doddreviewworker:string;
//  cbddreviewworker:string;
//  submitterphone:string;
//  submitteremail:string;
//  submitterfax:string;
//  nfphone:string;
//  nffax:string;
//  doddphone:string;
//  doddfax:string;
//  doddemail:string;
//  odmhphone:string;
//  odmhfax:string;
//  odmhemail:string;
//  cbddname:string;
//  cbddphone:string;
//  cbddfax:string;
//  cbddemail:string;
 
//  paaphone:string;
//  paafax:string;
//  paaemail:string;
//  createworker:string;
//  createdat:string;
  
//  consumername:string;
  
//  useragency:string;
//  createfacilityid:string;
//  sex:string;
//  username:string;
//  institutionid:string;
//  usertype:string;
//  attachmentid:string;
//  attachmentname:string;
//  prinT_IND:string;
//  fulL_ACCESS:string;
//  loaD_IND:string;
//  ediT_IND:string;
//  withdrawind:string;
//  deleteind:string;
//  fullvieW_IND:string;
//  dscreatoraccess:string;
//  dB_CREATE_FACILITY_ID:string;
//  dshospitalaccess:string;
//  dsnfaccess:string;
//  dspaaaccess:string;
//  dsodmhaccess:string;
//  dsdoddaccess:string;
//  dslocalaccess:string;
//  dbtype:string;
//  dB_TYPE:string;
//  reviewer:string;
//  aO_REQUEST_COMPLETE_DATE:string;
//  aO_DOC_REQUEST_COMMENTS:string;
//  aO_DOC_REQUEST_DATE:string;
//  aO_PAS_VALIDATION:string;
//  aO_PAS_EFFECTIVE_DATE:string;
//  aO_NEEdS_FACE_TO_FACE:string;
//  aO_ADVERSE_LOC_SCHED_DATE:string;
//  aO_DELAYED_LOC_NEEDED:string;
//  aO_DELAYED_EXEMPTION_REASON:string;
//  aO_LOC_RECOMMENDATION:string;
//  aO_LOC_DETERMINATION:string;
//  aO_LOC_DETERMINATION_DATE:string;
//  aO_LOC_DETERMINATION_STATUS:string;
//  aO_LOC_EFFECTIVE_DATE: string;
//  aO_PAA_DETERM_NOTE_DATE: string; 
        
//  scoreid:string;
//  alocid:string;
  
  
//  aO_CREATE_WORKER:string;
//  aO_ASSESS_TYPE:string;
//  aO_ENROLLMENT_REQUEST_DATE:string;
//  aO_ENROLLMENT_DATE:string;
//  aO_ESTIMATED_COST:string;
//  aO_DENIAL_REASON:string;
//  aO_PAS_ID:string;
//  aO_PAA_DETERM_NOTE:string;
//  aO_PAA_REVIEW_WORKER:string;
//  odmH_REASON_TEXT:string;
//}
