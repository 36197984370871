import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UpdateHeaderService } from '../../services/update-header.service';
import { FacilityService } from '../../services/facility.service';
import { DataService } from '../../services';
import { Dashboard } from '../../models/facility-profile/facility-dashboard';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  version: string = environment.VERSION;
  year;
  constructor(   private _updateHeader :UpdateHeaderService,private _facilityService: FacilityService,  private dataService: DataService,) { }
  facilityKey: number;
  isPilotFacility:string = "";
  ngOnInit() {
    //this.version = '3.0.0.0';
    this.year = new Date().getFullYear();
    this._updateHeader.notifyObservable$.subscribe(res => {
      if(res.refresh){
          // get your grid data again. Grid will refresh automatically
    this.facilityKey = this.dataService.vaccineContext.getContext().contextFacility?.facilityKey;

    this._facilityService.selectDashboard(this.facilityKey).subscribe((dashboardData: Dashboard) => {
    
    this.isPilotFacility = dashboardData.isPilotFacility;
      
    });
      }

      if(!res.refresh){
        this.isPilotFacility = null;
      }
})
  }

}
