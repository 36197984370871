import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-confirm-inactive-logout',
  templateUrl: './confirm-inactive-logout.component.html',
  styleUrls: ['./confirm-inactive-logout.component.css']
})
export class ConfirmInactiveLogoutComponent implements OnInit {

  counter: Subscription;
  count: number = 60;
  remainingCount: number;
  percentRemaining: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmInactiveLogoutComponent>
   ) { }

  ngOnInit(): void {

    if (this.counter == null || this.counter.closed) {
      this.counter = interval(1000).subscribe(cnt => {
        this.percentRemaining = Math.round(((this.count - cnt) / this.count) * 100);
        this.remainingCount = this.count - cnt;
        //console.log(this.remainingCount);
        if (this.remainingCount <= 0) {
          this.counter.unsubscribe();
          this.dialogRef.close('logoff');
        }
      });
    }

  }

  continue() {
    this.dialogRef.close('continue');
    this.counter.unsubscribe();
  }

  logoff() {
    this.dialogRef.close('logoff');
    this.counter.unsubscribe();
  }

}
