import { Injectable } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoaderService } from '.';
import { Facilities, FacilityContact } from '../models';
import { DomainValue } from '../models/DomainValueTypes';
import { Dashboard } from '../models/facility-profile/facility-dashboard';
import { FacilityNotes } from '../models/facility-profile/facility-notes';
import { FacilityOutReach } from '../models/facility-profile/facility-outreach.model';
import { FacilityValidation } from '../models/facility-profile/facility-validation';
import { LogFacilities } from '../models/facility-profile/logfacilities';
import { Facility } from '../models/facility.model';
import { ApiService } from './api.service';
import { DataService } from './data.service';
import { DomainValuesService } from './domain-values.service';
import { CovidCountyLevelTrans } from '../models/CovidCountyLevelTrans.model';
import { FacilityAffiliation } from '../models/facility-profile/facility-affiliations';
import { FacilityPayment } from '../models/facility-profile/facility-payment';
import { FacilityOtherCare } from '../models/facility-profile/facility-othercare';
import { FacilitySpcAssistance } from '../models/facility-profile/facility-spc-assistance';
import { FacilityStaff, FacilityStaffing, StaffRole } from '../models/facility-profile/facility-staff';
import { FacilityUpdateValidation } from '../models/facility-profile/facility-update-validation';


@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  constructor(
    private _httpService: ApiService,
    private _dataservice: DataService,
    private _domainService: DomainValuesService,
    private loaderService: LoaderService) { }

  contextFacilityLoaded = new Subject<any>();

  getFacility(id: any): Observable<any> {

    return this._httpService.get(`Facility/GetById?Id=${id}`, null, false);
  }

  getContextFacility(): Observable<any> {
    const id = this._dataservice.facility.getContext();
    return this.getFacility(id).pipe(tap(fac => {
      this.contextFacilityLoaded.next(fac);
    }));;
  }

  getPharmacies(facilityKey: any): Observable<any> {
    return this._httpService.get(`Facility/GetPharmacies/${facilityKey}`, null, null);
  }

  updateContact(contact: any): Observable<any> {
    return this._httpService.post('Facility/UpdateContact', contact, false);
  }

  getUserRolesByFacilityType(facType: string): Observable<DomainValue[]> {

    let domainKey = '';

    switch (facType) {
      case 'rcf':
        domainKey = 'FAC_CON_ROLES_RCF';
        break;
      case 'icf':
        domainKey = 'FAC_CON_ROLES_ICF';
        break;
      case 'nf':
        domainKey = 'FAC_CON_ROLES_NF';
        break;
      case 'aaa':
        domainKey = 'FAC_CON_ROLES_AAA';
        break;
      case 'dodd':
        domainKey = 'FAC_CON_ROLES_DODD';
        break;
      default:
        return of(null);
    }

    return this._domainService.getDomainValues(domainKey);
  }


  getFallPreventionTechnologies(): Observable<DomainValue[]> {

    let domainKey = 'FALLPREVENTION';
    return this._domainService.getDomainValues(domainKey);
  }

  selectFacility(id: number): Observable<Facilities> {
    return this._httpService.get(`Facility/SelectFacility/${id}`, null, false);
  }

  selectPhoto(id: number): Observable<string> {
    return this._httpService.get(`Facility/SelectPhoto/${id}`, null, false);
  }

  selectBroucher(id: number): Observable<string> {
    return this._httpService.get(`Facility/SelectBroucher/${id}`, null, false);
  }

  attachBroucher(attachment: any): Observable<any> {
    return this._httpService.postFile('Facility/attachBroucher', attachment);
  }

  searchFacility(name?: string, address?: string, license?: string, facType?: string): Observable<Facilities[]> {
    let search = '?';
    if (name) {
      search += `name=${name}&`;
    }
    if (address) {
      search += `address=${address}&`;
    }
    if (license) {
      search += `license=${license}&`;
    }
    if (facType) {
      search += `facType=${facType}&`;
    }
    return this._httpService.get(`Facility/SearchFacilities${search}`, null, false);
  }

  updateFacility(facility: any): Observable<any> {
    if (facility.IsAAA != true) {
      return this._httpService.post('Facility/UpdateFacility_Pharmacy', facility, false);
    } if (facility.IsAdmin != true) {
      return this._httpService.post('Facility/UpdateFacilityAAA', facility, false);
    } else {
      return this._httpService.post('Facility/UpdateFacilityAAAAdmin', facility, false);
    }
  }

  updateTestingOut(facilityKey: number): Observable<any> {
    return this._httpService.post(`Facility/UpdateFacilityTestingOut/${facilityKey}`);
  }

  selectFacilitiesContact(facilityKey: number): Observable<FacilityContact[]> {
    //GetFacilitiesContact
    return this._httpService.get(`Facility/GetFacilitiesContact/${facilityKey}`, null, false);
  }

  AddNewFacilityContact(facilityContact: FacilityContact): Observable<any> {
    return this._httpService.post(`Facility/AddNewFacilityContact`, facilityContact, false);
  }

  updateFacilityContact(facilityContact: FacilityContact): Observable<any> {
    return this._httpService.post(`Facility/UpdateFacilityContact`, facilityContact, false);
  }

  deleteFacilityContact(contactId: number): Observable<any> {
    return this._httpService.post(`Facility/DeleteFacilityContact/${contactId}`, null, false);
  }

  getVaccineAppUrls(): Observable<any> {
    return this._httpService.get('Facility/GetVaccineScheduleUrls', null, false);
  }

  getLtccgAppUrls(): Observable<any> {
    return this._httpService.get('Facility/GetLtccgUrls', null, false);
  }

  getFacilityPhoto(): Observable<any> {
    return this._httpService.get('Facility/GetLtccgUrls', null, false);
  }

  openLTCCGApp(){
    let ltcUrl = '';
    let usnm = this._dataservice.vaccineContext.getContext().contextUser?.userName
    let cred = this._dataservice.vaccineContext.getContext().contextUser?.password 

    this.getLtccgAppUrls().subscribe((urls: {
      devUrl: string,
      localUrl: string,
      prodUrl: string,
      testUrl: string
    }) => {
      let host = window.location.hostname;
      let ltcUrl = '';
      if (host.startsWith('local')) {
        ltcUrl = `${urls.localUrl}Admin/login?usnm=${usnm}&cred=${cred}`;
      }
      if (host.startsWith('dev')) {
        ltcUrl = `${urls.devUrl}Admin/login?usnm=${usnm}&cred=${cred}`;
      }
      if (host.startsWith('test')) {
        ltcUrl = `${urls.testUrl}Admin/login?usnm=${usnm}&cred=${cred}`;
      }
      if (host.startsWith('carecenter')) {
        ltcUrl = `${urls.prodUrl}Admin/login?usnm=${usnm}&cred=${cred}`;
      }
      window.location.href = ltcUrl;
     
  });

}





  openVaccinePortal() {

    this.getVaccineAppUrls().subscribe((urls: {
      devUrl: string,
      localUrl: string,
      prodUrl: string,
      testUrl: string
    }) => {
      let host = window.location.hostname;
      let targetHost = '';
      if (host.startsWith('local')) {
        targetHost = `${urls.localUrl}api/user/SetExternalLogin`;
      }
      if (host.startsWith('dev')) {
        targetHost = `${urls.devUrl}api/user/SetExternalLogin`;
      }
      if (host.startsWith('test')) {
        targetHost = `${urls.testUrl}api/user/SetExternalLogin`;
      }
      if (host.startsWith('carecenter')) {
        targetHost = `${urls.prodUrl}api/user/SetExternalLogin`;
      }
      // if (host.startsWith('')) {
      //   targetHost = `${urls.prodUrl}api/user/SetExternalLogin`;
      // }  
      var form = document.createElement("form");
      form.method = "POST";
      form.action = targetHost;
      form.target = "_blank";
      var params = {
        sessionid: this._dataservice.vaccineContext.getContext().sessionId,
        username: this._dataservice.vaccineContext.getContext().contextUser.userName,
        email: this._dataservice.vaccineContext.getContext().contextUser.email
      }
      for (let key in params) {
        var element1 = document.createElement("input");
        element1.type = "hidden";
        element1.value = params[key];
        element1.name = key;
        form.appendChild(element1);
      }
      document.body.appendChild(form);
      form.submit();
      //console.log('params', params);

    });
  }

  selectDashboard(id: number): Observable<Dashboard> {
    return this._httpService.get(`Facility/SelectDashboard/${id}`, null, false);
  }

  getLatestUpdate(id: number): Observable<LogFacilities> {
    return this._httpService.get(`Facility/GetLatestUpdate/${id}`, null, false);
  }

  getFacilityNotes(key: number): Observable<any> {
    return this._httpService.get(`Facility/GetFacilityNotes/${key}`, null, false);
  }

  addFacilityNotes(facilityNotes: FacilityNotes): Observable<any> {
    return this._httpService.post(`Facility/AddNewFacilityNotes`, facilityNotes, false);
  }
  updateFacilityNotes(facilityNotes: FacilityNotes): Observable<any> {
    return this._httpService.post(`Facility/UpdateFacilityNotes`, facilityNotes, false);
  }

  deleteFacilityNote(noteId: number): Observable<any> {
    return this._httpService.post(`Facility/DeleteFacilityNote/${noteId}`, null, false);
  }
  softDeleteFacilityNote(noteId: number): Observable<any> {
    return this._httpService.post(`Facility/SoftDeleteFacilityNote/${noteId}`, null, false);
  }

  getQuestLegacy(id: string): Observable<any> {
    return this._httpService.get(`Facility/GetQuestLegacy/${id}`, null, false);
  }

  searchFacilityOutReach(name?: string, address?: string, license?: string): Observable<FacilityOutReach[]> {
    let search = '?';
    if (name) {
      search += `name=${name}&`;
    }
    if (address) {
      search += `address=${address}&`;
    }
    if (license) {
      search += `license=${license}&`;
    }
    return this._httpService.get(`Facility/SearchOutReach${search}`, null, false);
  }

  AddNewOutReach(facOutReach: FacilityOutReach): Observable<any> {
    return this._httpService.post(`Facility/AddNewOutReach`, facOutReach, false);
  }

  getReportingDates(facId: number): Observable<any> {
    return this._httpService.get(`Facility/GetReportingDates?facId=${facId}`, null, null);
  }

  getFacilityByReportingDate(facId: number, reportingDate: string): Observable<any> {
    return this._httpService.get(`Facility/GetFacilityByReportingDate?facId=${facId}&reportingDate=${reportingDate}`, null, null);
  }

  updateFacilityValidationStatus(facilityValidation: FacilityValidation): Observable<any> {
    return this._httpService.post(`Facility/UpdateFacilityValidation`, facilityValidation, false);
  }
  exportFacilityProfile(key: string): Observable<any> {
    //FacilityRequests/exportFacilityProfile/100100
    return this._httpService.getFile(`FacilityRequests/exportFacilityProfile/${key}`, null, null);
  }

  getCovidCountyLevelTrans(facilityId: string): Observable<CovidCountyLevelTrans> {
    return this._httpService.get(`Facility/GetCummunityLevelTransmission?facilityId=${facilityId}`, null, null);
  }

  selectAffiliation(id: number): Observable<FacilityAffiliation> {
    return this._httpService.get(`Facility/SelectFacilityAffiliations/${id}`, null, false);
  }

  updateAffiliations(facilityAffiliation: any): Observable<any> {
    return this._httpService.post('Facility/UpdateFacilityAffiliations', facilityAffiliation, false);
  }

  selectPolicies(id: number): Observable<FacilityAffiliation> {
    return this._httpService.get(`Facility/SelectFacilityPolicies/${id}`, null, false);
  }

  updatePolicies(facilityPolicy: any): Observable<any> {
    return this._httpService.post('Facility/UpdateFacilityPolicies', facilityPolicy, false);
  }

  selectAmenities(id: number): Observable<FacilityAffiliation> {
    return this._httpService.get(`Facility/SelectFacilityAmenities/${id}`, null, false);
  }

  updateAmenities(facilityAmenities: any): Observable<any> {
    return this._httpService.post('Facility/UpdateFacilityAmenities', facilityAmenities, false);
  }

  getSharedCampusId(id: number): Observable<string> {
    return this._httpService.get(`Facility/GetSharedCampusId/${id}`, null, false);
  }

  updateSharedCampusId(param: any): Observable<any> {
    return this._httpService.post('Facility/UpdateSharedCampusId', param, false);
  }
  attachFile(attachment:any): Observable<any> {
    return this._httpService.postFile('Facility/attachFile', attachment);
  }

  selectPaymentSource(id: number): Observable<FacilityPayment[]> {
    return this._httpService.get(`Facility/SelectFacilityPaymentSource/${id}`, null, false);
  }

  updatePaymentSource(payments: any): Observable<any> {
    return this._httpService.post('Facility/UpdatePaymentSource', payments, false);
  }
  selectOtherCare(id: number): Observable<FacilityOtherCare> {
    return this._httpService.get(`Facility/SelectFacilityOtherCare/${id}`, null, false);
  }

  updateOtherCare(facilityOtherCare: any): Observable<any> {
    return this._httpService.post('Facility/UpdateFacilityOtherCare', facilityOtherCare, false);
  }
  selectSpcAssistance(id: number): Observable<FacilitySpcAssistance[]> {
    return this._httpService.get(`Facility/SelectFacilitySpcAssistance/${id}`, null, false);
  }

  updateSpcAssistance(spcAssistances: any): Observable<any> {
    return this._httpService.post('Facility/UpdateFacilitySpcAssistance', spcAssistances, false);
  }

  deleteSpcAssistance(facilityKey: number): Observable<any> {
    return this._httpService.post(`Facility/DeleteSpcAssistance/${facilityKey}`, null, false);
  }
  
  // getStaff(id: number): Observable<FacilityStaff[]> {
  //   return this._httpService.get(`Facility/GetStaffById/${id}`, null, false);
  // }

  getStaffing(id: number): Observable<FacilityStaffing> {
    return this._httpService.get(`Facility/GetStaffingById/${id}`, null, false);
  }

  updateFacilityStaffing(staffing: any): Observable<any> {
    return this._httpService.post('Facility/UpdateFacilityStaffing', staffing, false);
  }
  
  getStaffingRoles(id: number): Observable<any> {
    return this._httpService.get(`Facility/GetStaffingRoles/${id}`, null, false);
  }

  updatedStaffingRoles(staffingRoles: any): Observable<StaffRole[]> {
    return this._httpService.post('Facility/UpdatedStaffingRoles', staffingRoles, false);
  }

  selectFacilityUpdateValidation(id: number): Observable<FacilityUpdateValidation> {
    return this._httpService.get(`Facility/GetFacilityUpdateValidation/${id}`, null, false);
  }

  getFacilityValidated(id: number): Observable<any> {
    return this._httpService.get(`Facility/GetFacilityValidated/${id}`, null, false);
    
  }

  getTabValidationStatus(id: number): Observable<any> {
    return this._httpService.get(`Facility/GetTabValidationStatus/${id}`, null, false);
  }

  UpdateFacilityTabValidation(facValidation: any): Observable<any> {
    return this._httpService.post('Facility/UpdateFacilityTabValidation', facValidation, false);
  }

}
