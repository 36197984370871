<div class="container">
<div [class.loader-hidden]="!showAuthRefresh">
  <div class="loading-indicator">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">        
        <div class="modal-header card-header d-flex justify-content-center">
          <h5 class="modal-title" id="exampleModalCenterTitle">
            <mat-card-title>
              Session Expiration
            </mat-card-title>
          </h5>
        </div>
        <div class="modal-body">
          <p>Your session is expired.</p>
          <p>If you would like to extend the session.Please click "Yes" in <b><span style="color:darkred">{{_count}}</span> seconds.</b></p>
          <p>Please click "No" to logoff now.</p>
        </div>
        <div class="modal-footer">
          <button mat-raised-button (click)="hideExtend()">No</button>
          <button mat-raised-button color="primary" (click)="sessionExtend()">Yes</button>
        </div>
      </div>
    </div>
  </div>

</div>
</div>

