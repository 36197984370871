<h1 mat-dialog-title>
  <mat-icon class="text-danger">HENS Message!</mat-icon>
  <span style="position:relative;top:-5px" class="ml-3"> </span>
</h1>
<div mat-dialog-content>
  <p> message</p>
  <mat-label id="matlbl"></mat-label>
</div>
<hr />
<div mat-dialog-actions class="d-flex flex-row-reverse">
  <button class="m-2" mat-raised-button color="warn" (click)="confirm('yes')">Yes</button>
  <button class="m-2" mat-stroked-button color="primary" (click)="confirm('no')" cdkFocusInitial>No</button>
</div>
