import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UpdateHeaderService {

  constructor() { }

  public notify = new BehaviorSubject<any>('');
  notifyObservable$ = this.notify.asObservable();

    public notifyHeader(data: any) {
    if (data) {
        this.notify.next(data);
    }
}
}


