
<div class="container" style="padding-top:100px;margin-bottom:200px;">
  <!-- <div class="card-body ">
    <section class="card main banner">
      
        <section >
          <p style="font-size: medium;margin-top: 10px;padding-left: 10px;"><b>11/04 Update: </b> ODA has reset all user passwords previously issued on Friday, November 1. New temporary password emails will be distributed later today (11/04/24). For questions, please contact R3AP at <a href="mailto:R3APrequest@age.ohio.gov">R3APrequest@age.ohio.gov</a>.</p>
          <hr>
          <p style="font-size: medium;margin-top: 10px;padding-left: 10px;"><b>11/01 Attention: </b>The Care Center portal has many new features, including enhanced security (See the updated User Guide at the top right after login). Users will now be required to have unique, individual credentials to access the system. All users will be receiving two (2) emails: one with their new username and one with a temporary password. If you have any questions, please contact R3AP at  <a href="mailto:R3APrequest@age.ohio.gov">R3APrequest@age.ohio.gov</a>.</p>
        </section>
    </section>
  </div> -->
  <div class="row row-cols-3">

    <div class="col offset-4">

      <div class=" card mat-elevation-z5 mt-5" [formGroup]="authForm">

        <div class="card-header d-flex justify-content-center">
          <mat-card-title>
            Login
          </mat-card-title>
        </div>

        <div class="card-body">

          <div class="row ">

            <mat-form-field class="col" appearance="outline">
              <mat-label>User Name</mat-label>
              <input id="userName" matInput placeholder="User Name" formControlName="UserName" type="text" required>

              <mat-error id="userNameRequiredError" *ngIf="authForm.get('UserName').value == ''">
                User Name is <strong>required</strong>
              </mat-error>
              <mat-error id="userNameInvalidError" *ngIf="authForm.get('UserName')?.invalid && authForm.get('UserName').value != ''">
                Please enter valid characters
              </mat-error>
            </mat-form-field>

          </div>

          <div class="row ">

            <mat-form-field class="col" appearance="outline">
              <mat-label>Password</mat-label>
              <input id="password" matInput placeholder="Password" formControlName="Password" type="password" required>

              <mat-error id="passwordRequiredError" *ngIf="authForm.get('Password').value == ''">
                Password is <strong>required</strong>
              </mat-error>
            </mat-form-field>

          </div>

          <div class="row justify-content-center align-content-center">
            <button id="submit" mat-raised-button color="primary" type="submit" (click)="submitForm()" [disabled]="isSubmitting">
              Login
            </button>
          </div>
          <br/>
          <div class="row justify-content-center align-content-center">
            <button id="forgotPassword" mat-raised-button color="warn" routerLink="forgot-password" type="submit">
              Forgot Password
            </button>
          </div>


        </div>

        <mat-progress-bar *ngIf="isSubmitting" mode="indeterminate"></mat-progress-bar>

      </div>

    </div>
    

  </div>

</div>


<!--<div style="text-align:center; vertical-align:middle;height:300px;width:100%;margin-top:100px;">
  <a class="mr-3" mat-raised-button color="primary" (click)="loginAsPharmacy()">Login as Pharmacy</a>
  <a class="mr-3" mat-raised-button color="primary" (click)="loginAsFacility()">Login as Facility</a>
  <a mat-raised-button color="primary" (click)="LoginAsAdmin()">Login as Admin</a>
</div>-->
