<div class="container" style="padding-top:100px;margin-bottom:300px;">
  <div class="card mat-elevation-z8 mt-5">
    <div class="card-header d-flex justify-content-center">
      <mat-card-title>
        Reset Password
      </mat-card-title>
    </div>
    <div class="container card-body" style="font-size:small">
      <section class="row">
        <section class="col">
          <p class="text-left"> To reset your password, select facility from drop down list and enter your email and click the 'Reset password' button.</p>
          <p class="text-left"> An email message will be sent to the email address you used when creating the account.</p>
          <p class="text-left">If you do not receive your email within five minutes check your spam folder.</p>
        </section>
      </section>
      <section class="row">
        <section class="col-8">
          <mat-card>
            <!--<section class="row p-4">-->
              <div class="row">
                <div class="col-10">
                  <mat-form-field>
                    <mat-label class="asterisk_input">Search Facility</mat-label>
                    <input maxlength="60" #facilitySearch matInput type="search" placeholder="Search Facility" [formControl]="facilitySearch1" [matAutocomplete]="facilitySearch2">
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-hint align="start">Maximum 60 characters</mat-hint>
                    <mat-autocomplete #facilitySearch2="matAutocomplete" (optionSelected)="facilitySelected22($event)">
                      <mat-option *ngFor="let fac of filteredFacilitiesSearch | async" [value]="fac">
                        {{fac.name + "-" + fac.stateId}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="facilitySearch1.errors?.required">
                      Required*
                    </mat-error>
                  </mat-form-field>
                </div>                
              </div>
              <div class="row">
                <div class="col-6">
                  <br />
                  <mat-form-field appearance="outline">
                    <mat-label>Please Enter Email</mat-label>
                    <input matInput [formControl]="emailAddress" maxlength="60" appFilterChars required>
                    <mat-error *ngIf="emailAddress.invalid">Valid Email Required*</mat-error>
                  </mat-form-field>
                  <a mat-raised-button routerLink=''>Back to Login</a>
                  <!-- (click)="router.navigate([''])" -->
                </div>
                <div class="col pt-4">
                  <!--<div class="d-flex">-->
                    <button [disabled]="emailAddress.invalid || facilitySearch1.invalid || processing" mat-raised-button color="primary" (click)="onForgotPassword()">
                      <div class="d-flex">
                        <span>Reset password</span>
                        <mat-spinner *ngIf="processing" color="warn" diameter="20" class="ml-2 mt-2"></mat-spinner>
                      </div>
                    </button>

                  <!--</div>-->
                </div>
              </div>
              
            <!--</section>-->
          </mat-card>
        </section>
      </section>



    </div>
  </div>
</div>
