import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-search-facility',
  templateUrl: './search-facility.component.html',
  styleUrls: ['./search-facility.component.css']
})
export class SearchFacilityComponent implements OnInit, AfterViewInit {

  showcss = false;

  constructor() { }

  ngOnInit(): void {

    

  }

  ngAfterViewInit(): void {
  }

}
