import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[changeToUpperCase]'
})
export class ChangeToUpperDirective {

  constructor() { }

  @HostListener('change', ['$event'])
  onChange(e: Event) {
    e.srcElement['value'] = e.srcElement['value'].toString().toUpperCase();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    e.srcElement['value'] = e.srcElement['value'].toString().toUpperCase();
  }
}
