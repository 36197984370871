<form #ssngroup [formGroup]="ssnForm" (focus)="OnFocus($event)" class="ssn-form " [class.ssn-readonly]="readOnly">
  <div class="d-flex">
    <div class="d-flex" (focus)="OnFocus($event)" (blur)="OnBlur($event)">
      <input style="width:30%" (focus)="OnFocus($event);" #ssn1 class="mat-input-element ssn-masked-field " maxlength="3" [type]="showSsn?'text':'password'"
             formControlName="ssn1" appFilterChars allowedChars="[0-9]" [readonly]="readOnly" />

      <span *ngIf="(focused && empty) || !empty" class="ssn-hypen align-self-center">-</span>

      <input style="width:20%" (focus)="OnFocus($event);" #ssn2 class="mat-input-element ssn-visible-field " maxlength="2" [type]="showSsn?'text':'password'"
             formControlName="ssn2" appFilterChars allowedChars="[0-9]" [readonly]="readOnly" />

      <span *ngIf="(focused && empty) || !empty" class="ssn-hypen align-self-center">-</span>

      <input style="width:40%" (focus)="OnFocus($event);" #ssn3 class="mat-input-element ssn-masked-field" maxlength="4" type="text"
             formControlName="ssn3" appFilterChars allowedChars="[0-9]" [readonly]="readOnly" />
    </div>
    <div class="">
      <button class="" mat-icon-button (click)="toggleVisibility()">
        <mat-icon style="margin-top: -5px;color:#8d8787" *ngIf="!showSsn">visibility_off</mat-icon>
        <mat-icon style="margin-top: -5px;color:#ff6a00;" *ngIf="showSsn">visibility</mat-icon>
      </button>
    </div>
  </div>
</form>
