import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

  constructor(private el: ElementRef) { }

  AllowedKeys = ['Tab','Backspace', 'Delete', 'ArrowLeft', 'ArrowDown', 'ArrowRight', 'ArrowUp', 'Insert'];


  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    //console.log(e);
    if (this.AllowedKeys.indexOf(e.key) > -1) {
      return;
    }
    let regexp = /^[0-9]+$/;
    if (!regexp.test(e.key)) {
      e.preventDefault();
    }
  }

}
