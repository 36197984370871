<mat-dialog-content class="mat-typography overflow-y-off py-0">
  <div class="row">
    <div class="col mb-3">

      <div *ngIf="currentSteps == 1">
        <mat-accordion>
          <p style="color: maroon;">
            The state can provide up to  <b style="background-color: #FFFF00">{{maxBoxSupplyNum}}</b> antigen boxes for the next 30 days based on
            your facility's most recently self-reported census numbers level of testing
            support, and county community transmission level.Would you like to order less
            than this amount?
          </p>
        </mat-accordion>
        <mat-dialog-actions align="center" class="my-0 py-0" style="color:maroon">
          <button  class="text-bold mat-button mat-raised-button mat-button-base mat-primary" (click)="next()">Yes</button>
          <button mat-button class="text-bold mat-button mat-raised-button mat-button-base mat-primary" (click)="goBack()">No</button>
          <button mat-button class="text-bold mat-button mat-raised-button mat-button-base mat-primary" [mat-dialog-close]="">Cancel</button>
        </mat-dialog-actions>
      </div>
      <div *ngIf="currentSteps == 2">
         <mat-accordion>
           <div class="d-flex flex-column">
             <mat-form-field>
               <mat-label class="asterisk_input">How many boxes would you like to order ? (40 tests per box)**</mat-label>
               <input [(ngModel)]="antigenBoxEntered"  (ngModelChange)="supplyBoxOnChange($event)" min="1"  [max]="maxBoxSupplyNum" maxlength="60" matInput type="number" placeholder="Antigen Supply Boxes">
               <mat-hint class="antigenSupplyDialogErro" style="color:red" *ngIf="!antigenBoxEnteredIsValid">Please enter a number between 1 to {{maxBoxSupplyNum}}</mat-hint>
             </mat-form-field>
           </div>
            
         </mat-accordion>
         <mat-dialog-actions align="center" class="my-0 py-0" style="color:maroon">
          <button class="text-bold mat-button mat-raised-button mat-button-base mat-primary" [disabled]="!antigenBoxEnteredIsValid" (click)="next()">Save</button>
         </mat-dialog-actions>
      </div>
      <div *ngIf="currentSteps == 3">
        <mat-dialog-actions align="center" class="my-0 py-0" style="color:maroon">
          <mat-label class="asterisk_input">Are you sure this amount will cover your facility for the next 30 days ?</mat-label>
          <div class="mt-2">
            <button class="text-bold mat-button mat-raised-button mat-button-base mat-primary" (click)="submitAntigenBox()">Yes</button>
            <button mat-button class="text-bold mat-button mat-raised-button mat-button-base mat-primary" (click)="goBack()">No</button>
          </div>
        </mat-dialog-actions>
      </div>
    </div>
  </div>

</mat-dialog-content>
