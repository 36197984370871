import { Injectable } from '@angular/core';
import { ApiService } from '.';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { County, DomainValue } from '../models/DomainValueTypes';
import { ODAHoliday } from '../models/TestScheduleTypes';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DomainValuesService {

  constructor(private apiService: ApiService) { }

  getCounties(): Observable<County[]> {
    return this.apiService.get('DomainValues/getCounties',null,false).pipe(map(data => {
      return data;
    }));
  }

  getDomainValues(domainName: string): Observable<DomainValue[]> {
    return this.apiService.get(`DomainValues/GetDomainValues/${domainName}`,null, false).pipe(map(data => {
      return data;
    }));
  }

  getODAHolidays(): Observable<ODAHoliday[]> {
    return this.apiService.get('DomainValues/getODAHolidays').pipe(map((val: { holidayDate: any, holidayName: any }[] ) => {
      let holidays: ODAHoliday[] = [];
      val.forEach(h => holidays.push(new ODAHoliday(h.holidayDate, h.holidayName)));
      return holidays;
    })
    );
  }


}
