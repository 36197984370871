
  <mat-horizontal-stepper labelPosition="bottom" #stepper class="container-fluid">
    <mat-step *ngFor="let step of stepNodes; let i = index">
      <ng-template matStepLabel>
        <b>{{step.title}}</b>
        <br/>
        {{step.description}}
      </ng-template>
    </mat-step>
  </mat-horizontal-stepper>


<!--<mat-step>
  <ng-template matStepLabel> <b>Section A</b><br />Individual Information </ng-template>
</mat-step>
<mat-step>

  <ng-template matStepLabel><b>Section B</b><br />Health Information</ng-template>

</mat-step>
<mat-step>
  <ng-template matStepLabel><b>Section B1</b><br />Skilled Nursing & Rehab</ng-template>

</mat-step>
<mat-step>
  <ng-template matStepLabel><b>Section B2</b><br />Assessment of Medical Stability</ng-template>

</mat-step>
<mat-step>
  <ng-template matStepLabel><b>Section C</b><br />ADLs and IADLs</ng-template>

</mat-step>
<mat-step>
  <ng-template matStepLabel><b>Section D</b><br />BCAT Screen</ng-template>

</mat-step>
<mat-step>
  <ng-template matStepLabel><b>Section E</b><br />Screenings</ng-template>

</mat-step>
<mat-step>
  <ng-template matStepLabel><b>Summary</b><br />ALOCQ Summary</ng-template>

</mat-step>-->
<!-- <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      You are now done.
      <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="stepper.reset()">Reset</button>
      </div>
      (selectionChange)="selectionChange($event)"
  </mat-step> -->
