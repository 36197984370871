import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyletters]'
})
export class OnlylettersDirective {

  constructor(private el: ElementRef) { }

  AllowedKeys = ['Tab', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowDown', 'ArrowRight', 'ArrowUp', 'Insert'];

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (this.AllowedKeys.indexOf(e.key) > -1) {
      return;
    }
    let regexp = /^[A-Za-z]+$/;
    if (!regexp.test(e.key)) {
      e.preventDefault();
    }
  }

}
