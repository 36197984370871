import { Component, OnInit, ViewChild, ElementRef, forwardRef, OnDestroy, HostBinding, Input, Injector, DoCheck, AfterViewInit, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, FormGroup, FormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, timer, Observable, Subscriber, Subscription } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { EventManager } from '@angular/platform-browser';

@Component({
  selector: 'app-ssn-field',
  templateUrl: './ssn-field.component.html',
  styleUrls: ['./ssn-field.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SsnFieldComponent),
    multi: true
  },
  {
    provide: MatFormFieldControl,
    useExisting: SsnFieldComponent
  }]
})
export class SsnFieldComponent implements OnInit, ControlValueAccessor, MatFormFieldControl<any>, OnDestroy, DoCheck, AfterViewInit {


  setDisabledState?(isDisabled: boolean): void {

  }
  stateChanges = new Subject<void>();

  static nextId = 0;
  @HostBinding()
  id = `app-ssn-field-${SsnFieldComponent.nextId++}`;

  _placeholder = '';
  @Input()
  set placeholder(value) {
    this._placeholder = value;
  };
  get placeholder() {
    return this._placeholder;
  }

  ngControl: NgControl;

  _focused = false;
  @Input()
  set focused(value) {
    this._focused = value;
  }
  get focused() {
    return this._focused;
  }

  get empty(): boolean {
    return this.value == '';
  };

  _shouldLabelFloat = true;
  @Input()
  set shouldLabelFloat(value) {
    this._shouldLabelFloat = value;
  }
  get shouldLabelFloat() {
    if (this.focused ) {
      return true;
    }
    return !this.empty;
  }

  _required = false;
  @Input()
  get required(): boolean {
    return this._required;
  };
  set required(req: boolean) {
    this._required = req;
  }
  _disabled = false;
  @Input()
  get disabled(): boolean {
    return this._required;
  };
  set disabled(disable: boolean) {
    this._disabled = disable;
  }
  _errorState = false;
  get errorState(): boolean {
    return this._errorState;
  };
  set errorState(errState: boolean) {
    this._errorState = errState;
  }

  controlType?: string = 'ssn-field';
  autofilled?: boolean = false;

  @HostBinding('attr.aria-describedby')
  describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      //this.ssn1.nativeElement.focus();
    }
  }


  _value: any;
  get value() {
    return this._value;
  }
  set value(val: any) {
    this._value = val;
    this.loadSsnToUI(val);
  }

  private loadSsnToUI(ssn: string) {
    ssn = ssn == null ? '' : ssn;
    ssn = ssn.replace(/-/g, '');
    let ssn1 = '', ssn2 = '', ssn3 = '';
    if (ssn.length >= 3) {
      ssn1 = ssn.substr(0, 3);
    }
    if (ssn.length >= 5) {
      ssn2 = ssn.substr(3, 2);
    }
    if (ssn.length >= 9) {
      ssn3 = ssn.substr(5, 4);
    }
    this.ssnForm.setValue({
      ssn1: ssn1,
      ssn2: ssn2,
      ssn3: ssn3
    });
  }

  writeValue(val: any): void {
    this.value = val;
  }
  _onChange: (_: any) => {};
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  _onFocus: (_: any) => {};

  _onTouched: (_: any) => {};
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }



  @ViewChild('ssngroup', { static: true })
  ssngroup: ElementRef;

  @ViewChild('ssn1', { static: true })
  ssn1: ElementRef;
  @ViewChild('ssn2', { static: true })
  ssn2: ElementRef;
  @ViewChild('ssn3', { static: true })
  ssn3: ElementRef;

  ssnForm = new FormGroup({
    ssn1: new FormControl(),
    ssn2: new FormControl(),
    ssn3: new FormControl()
  });

  showSsn = false;

  @Input()
  ShowSSNFor = 5;

  @Input()
  readOnly: boolean = false;

  constructor(public elRef: ElementRef, public injector: Injector, fm: FocusMonitor, private eventManager: EventManager) {
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();

      if (!this.focused) {
        if ((this.timerSub && this.timerSub.closed) || this.timerSub == null) {
          this.showSsn = false;
        }
      } else {
        if (this.value.length != 9) {
          this.showSsn = true;
        }
      }


    });

  }

  ngOnInit(): void {

    this.ngControl = this.injector.get(NgControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }


  }
  ngOnDestroy(): void {
    this.stateChanges.complete();
  }
  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  ngAfterViewInit(): void {

    let exclude = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight','Delete'];
    //ssn1
    this.eventManager.addEventListener(this.ssn1.nativeElement, 'keydown', (event: KeyboardEvent) => {
      if (exclude.indexOf(event.code) >= 0) {
        return;
      }      
      let ssn1Value = this.ssn1.nativeElement.value;
      if (ssn1Value != null && ssn1Value != '') {
        if (ssn1Value.toString().length == 3) {
          this.ssn2.nativeElement.focus();
        }
      }
    });
    //ssn2
    this.eventManager.addEventListener(this.ssn2.nativeElement, 'keydown', (event: KeyboardEvent) => {
      if (exclude.indexOf(event.code) >= 0) {
        return;
      }
     
      let ssn2Value = this.ssn2.nativeElement.value;
      if (ssn2Value != null && ssn2Value != '') {
        if (ssn2Value.toString().length == 2) {
          this.ssn3.nativeElement.focus();
        }
      }
    });
    //ssn3
    this.eventManager.addEventListener(this.ssn3.nativeElement, 'keydown', (event: KeyboardEvent) => {
      if (exclude.indexOf(event.code) >= 0) {
        return;
      }      
    });

    //blur event
    //this.eventManager.addEventListener(this.ssn1.nativeElement, 'blur', (event: KeyboardEvent) => {
    //  toggleViewAfterTab();
    //});
    //this.eventManager.addEventListener(this.ssn2.nativeElement, 'blur', (event: KeyboardEvent) => {
    //  toggleViewAfterTab();
    //});
    //this.eventManager.addEventListener(this.ssn3.nativeElement, 'blur', (event: KeyboardEvent) => {
    //  toggleViewAfterTab();
    //});

    //form input change
    let emptyonnull = (val) => {
      return val == null ? '' : val;
    }
    this.ssnForm.valueChanges.subscribe(value => {
      this._value = `${emptyonnull(value.ssn1)}${emptyonnull(value.ssn2)}${emptyonnull(value.ssn3)}`;
      this._onChange(this._value);
      this._onTouched(this._value);
      //console.log('value change', this._value);
      if (this._value.length < 9) {
        this.showSsn = true;
        if (this.timerSub && !this.timerSub.closed) {
          this.timerSub.unsubscribe();
        }
      }
    });


  }





  //onChange(event: any) {
  //  console.log(event);
  //  this._onChange(event.target.value);
  //  this.value = event.target.value;
  //}
  OnFocus(event: any) {
    //this._onFocus(event);
    this.focused = true;
    //this.ssn1.nativeElement.focus();
    //console.log('focus called');
  }
  OnBlur(event: any) {
    this.focused = false;
  }

  timerSub: Subscription;
  toggleVisibility() {
    this.showSsn = !this.showSsn;
    if (this.showSsn) {
      if (this.timerSub != null && !this.timerSub.closed) {
        this.timerSub.unsubscribe();
      }
      this.timerSub = timer(this.ShowSSNFor * 1000).subscribe(t => {
        if (this.value.length == 9) {
          this.showSsn = false;
        }        
      });
    }
  }

  


}
