import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { UserService, LoaderService, DataService, AuthService } from '../app/shared/services';
import { MessageBoxService } from './shared/services/message-box.service';
import { interval, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmInactiveLogoutComponent } from './shared/components/confirm-inactive-logout/confirm-inactive-logout.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  showMatStepper: boolean = false;
  showConsumerInfo: boolean = true;

  timer: Subscription;

  constructor(
    private userService: UserService,
    private router: Router,
    private actRounter: ActivatedRoute,
    private loaderService: LoaderService,
    private dataService: DataService,
    private authService: AuthService,
    private _msgService: MessageBoxService,
    public dialog: MatDialog) {
  }



  ngOnInit() {

    this.userService.checkVaccineContext();

    if (this.dataService.vaccineContext.getToken() != null) {
      if (this.userService.getTokenRemainingTime() <= 0) {

        this._msgService.error({
          title: 'Session Expired',
          message: 'Your session expired. Please login to continue'
        }).subscribe(_ => {
          this.userService.logOff();
        });

      } else {
        this.userService.startTokenTimer();
        this.watchUserLastTransaction();
      }


    }

    this.dataService.vaccineContext.contextLoaded.subscribe(_ => {
      this.watchUserLastTransaction();
    });

  }

  ngAfterViewInit() {

    this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart) {
        this.loaderService.showLoader();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel ) {
        this.loaderService.hideLoader();
      }
    });

  }

  watchUserLastTransaction() {

    if (this.timer == null || this.timer.closed) {

      this.timer = interval(60000*5).subscribe(_ => {
        //console.log('timer');
        this.userService.getLastTranTime().subscribe((date: { lastTranTime: string }) => {
          //console.log(date);
          const diff = Date.now() - new Date(date.lastTranTime).getTime();
          const lastMin = new Date(diff).getUTCMinutes();
          //console.log(lastMin);
          if (lastMin >= 30) {

            const dialogRef = this.dialog.open(ConfirmInactiveLogoutComponent, {
              disableClose: true,
              data: null
            });

            this.timer.unsubscribe();

            dialogRef.afterClosed().subscribe(result => {

              if (result == 'continue') {
                this.userService.updateLastTransactionTime().subscribe(_ => {
                  this.watchUserLastTransaction();
                }, err => {
                    console.error(err);
                    this._msgService.error({
                      title: 'Error',
                      message: 'Error extending session'
                    });
                })
                
              } else if (result = 'logoff') {
                this.userService.logOff();
              }

            });


          }

        });


      });

    }


  }


}
