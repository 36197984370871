<div class="container">
  <div class="row mt-4 ">
    <div class="col message-container d-flex flex-column align-items-center justify-content-center">
      <h1 class="banner">No Access</h1>
      <p>
        Your account does not have permission to view this page
      </p>
    </div>
  </div>
</div>
