import { Component, OnInit, ViewChild, ComponentFactoryResolver, ApplicationRef, Injector, OnDestroy } from '@angular/core';
import { CdkPortal, DomPortalHost, ComponentPortal } from '@angular/cdk/portal';
import { SearchFacilityComponent } from '../../../schedules-public-view/search-facility/search-facility.component';

@Component({
  selector: 'app-show-window',
  templateUrl: './show-window.component.html',
  styleUrls: ['./show-window.component.css']
})
export class ShowWindowComponent implements OnInit, OnDestroy {
    

  //@ViewChild(CdkPortal, { static: true } ) portal: CdkPortal;
  private externalWindow = null;

  componentPortal: ComponentPortal<SearchFacilityComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector) { }

  ngOnInit(): void {
    this.externalWindow = window.open('', '', 'width=600,height=400,left=200,top=200');
    const host = new DomPortalHost(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );
    // host.attach(this.portal);
    host.attachComponentPortal(this.componentPortal = new ComponentPortal(SearchFacilityComponent));
  }
  ngOnDestroy(): void {
    this.externalWindow.close();
  }

}
